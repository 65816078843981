:root {
    --base-font-color: #424242;
    --base-font-family: Lato, Arial, sans-serif;
    --base-font-weight: normal;
    --base-font-size: 1rem;
    --base-line-height: 1.5;
    --base-h1-font-size: 24px;
    --base-h1-line-height: 1.5;
    --base-h2-font-size: 24px;
    --base-h2-line-height: 1.5;
    --base-h3-font-size: 23px;
    --base-h3-line-height: 1.56;
    --base-h4-font-size: 1.2rem;
    --base-h4-line-height: 1.4;
    --base-h5-font-size: 1rem;
    --base-h5-line-height: 1.4;
    --base-h6-font-size: 0.875rem;
    --base-h6-line-height: 1.4;
    --base-margin: 24px;
    --color-primary: #1f2e51;
}
*,
*::before,
*::after {
    box-sizing: border-box;
    min-height: 0;
    min-width: 0;
}

html {
    background-color: #fff;
    box-sizing: border-box;
    color: var(--base-font-color);
    font-family: var(--base-font-family);
    line-height: var(--base-line-height);
    font-size: var(--base-font-size);
    font-weight: var(--base-font-weight);
}

body {
    margin: 0;
}

.wrapper {
    min-height: 100vh;
    overflow: hidden;
    position: relative;
}

a {
    background-color: transparent;
    color: var(--color-primary);
    cursor: pointer;
    text-decoration: none;
}

p {
    line-height: var(--base-line-height);
}

a:active,
a:hover,
a:focus {
    outline: none;
}

a:hover {
    color: var(--color-primary);
    filter: brightness(1.1);
    text-decoration: underline;
}

b,
strong {
    font-weight: bolder;
}

audio,
canvas,
img,
video {
    height: auto;
    max-width: 100%;
}

svg {
    max-height: 100%;
    max-width: 100%;
}

svg:not(:root) {
    overflow: hidden;
}

img:not([src]) {
    visibility: hidden;
}

p,
ul,
ol {
    margin: 0 0 var(--base-margin) 0;
}

* + p,
* + ul,
* + ol {
    margin-top: var(--base-margin);
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: inherit;
    font-family: var(--base-font-family);
    font-weight: bold;
    margin: 0 0 var(--base-margin) 0;
}

* + h1,
* + h2,
* + h3,
* + h4,
* + h5,
* + h6 {
    margin-top: var(--base-margin);
}

h1,
.h1 {
    font-size: var(--base-h1-font-size);
    line-height: var(--base-h1-line-height);
}

h2,
.h2 {
    font-size: var(--base-h2-font-size);
    line-height: var(--base-h2-line-height);
}

h3,
.h3 {
    font-size: var(--base-h3-font-size);
    line-height: var(--base-h3-line-height);
}

h4,
.h4 {
    font-size: var(--base-h4-font-size);
    line-height: var(--base-h4-line-height);
}

h5,
.h5 {
    font-size: var(--base-h5-font-size);
    line-height: var(--base-h5-line-height);
}

h6,
.h6 {
    font-size: var(--base-h6-font-size);
    line-height: var(--base-h6-line-height);
}

ul,
ol {
    padding-left: 30px;
}

ul > li > ul,
ul > li > ol,
ol > li > ol,
ol > li > ul {
    margin: 0;
}

iframe {
    border: 0;
    width: 100%;
}

.d-flex {
    display: flex;
}

.d-block {
    display: block;
}

.flex-column {
    flex-direction: column;
}

.justify-content-center {
    justify-content: center;
}

.justify-content-between {
    justify-content: space-between;
}

.justify-content-end {
    justify-content: flex-end;
}

.align-items-center {
    align-items: center;
}

.align-self-start {
    align-self: flex-start;
}

.text-align-center {
    text-align: center;
}

.w100 {
    width: 100%;
}

ul.list {
    margin-bottom: 0;
    padding-left: 0;
}

.required:after {
    content: '*';
    color: #cc3b3b;
    display: inline-flex;
    padding-left: 3px;
}

.text-center {
    text-align: center;
}

.apos-area h2 {
    font-size: 24px;
}
.no-margin {
    margin: 0;
}

.blockquote {
    margin-top: 10px;
    margin-left: 15px;
    font-size: 13px;
}

.link-underline {
    text-decoration: underline;
}

.text-overflow {
    overflow: hidden;
    text-overflow: ellipsis;
}

#modal-root > * {
    z-index: 100;
}

.vertical-margins {
    margin: 10px 0;
}

@media (min-width: 768px) {
    h1 {
        font-size: 48px;
        line-height: 1.25;
    }
    h2 {
        font-size: 36px;
        line-height: 1.33;
    }
    h3 {
        font-size: 30px;
        line-height: 1.5;
    }
}

@media (max-width: 1440px) {
    .responsive-form .form-row .form-group {
        width: 100%;
    }
}

#dropin-container {
   font-family: Lato, Arial, sans-serif;
}
  
#dropin-container [data-braintree-id="methods-label"] {
    font-family: Lato, Arial, sans-serif;
    color: var(--base-font-color);
}

#dropin-container [data-braintree-id="methods-edit"] {
    font-family: Lato, Arial, sans-serif;
    color: var(--base-font-color);
}

.simple-link {
    color: #044696;
    text-decoration:underline;
}
.simple-link:hover {
    text-decoration:none;
}